export default {
  heading: 'Satisfy, sans-serif', //Farsan
  subheading: 'Poiret One, serif',
  body: 'Comfortaa, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Zen Tokyo Zoo, serif',
  display2: 'Satisfy',
  googleFonts: [
    'Comfortaa:100,200,300,400,500,600,700,800,900',
    'Playfair Display:400,500,600,700,800,900',
    'Poiret One',
    'Farsan',
    'Zen Tokyo Zoo',
    'Satisfy'
  ]
  // customFamilies: ["Din"],
  // customUrls: ["https://www.gonation.biz/fonts/din/din.css"],
}
