export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {},
    '.containerScrolled': {
      backgroundColor: 'black',
      borderBottom: 'solid 2px',
      borderColor: 'primary'
    },
    '.logo': {
      img: {
        maxHeight: '90px',
        filter: 'unset'
      }
    },
    '.logoScrolled': {
      img: {
        maxHeight: '50px',
        filter: 'unset'
      }
    },
    '.hamburger': {
      borderColor: 'secondary',
      '>div': {
        backgroundColor: 'secondary'
      }
    },
    '.hamburgerOpen': {
      borderColor: 'secondary',
      '>div': {
        backgroundColor: 'secondary'
      }
    },

    '.navMenu': {
      backgroundColor: 'black',
      minWidth: 'unset',
      padding: ['2rem 1rem', '', '', '2rem 1rem 1rem', '2rem 2rem 1rem'],
      alignItems: ['center', '', '', 'flex-start']
    },
    '.navMenuOpen': {
      backgroundColor: 'black',
      color: 'white'
    },

    '.navMenuLogo': {
      img: {
        maxHeight: '100px'
      },
      marginBottom: '1.5rem'
    },

    '.navItem': {
      margin: '0.5rem',

      paddingLeft: '2rem',
      color: 'white'
    },
    '.smallNavHeading': {
      color: 'secondary'
    },
    '.phoneSocialContainer': {
      a: {
        fontSize: '1.25rem'
      }
    },
    '.socialLink': {
      svg: {
        path: {
          fill: 'white'
        }
      }
    }
  },

  footer: {
    backgroundColor: 'black',
    padding: ['0.5rem 0.5rem 3rem', '0.5rem 0.5rem 3rem', '0.5rem', '0.5rem'],
    minWidth: '80vw',
    maxWidth: ['95vw', '', '80vw'],
    margin: '0rem auto',
    width: '100%',

    '.connectColumn': {
      width: ['100%', '100%', '100%', '100%', '100%'],
      display: 'flex',
      flexDirection: ['column', '', '', 'row'],
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0rem',
      maxWidth: 'unset',
      marginBottom: '0rem'
    },
    '.aboutColumn': {
      display: 'none'
    },
    '.exploreColumn': {
      display: 'none'
    },
    '.logoColumn': {
      display: 'none'
    },
    '.socialContainer, .contactDetails-container': {
      display: 'none',
      svg: {
        path: {
          fill: 'grey'
        }
      }
    },
    '.logo': {
      filter: 'brightness(0) invert(1)',
      display: 'none'
    },

    '.sectionHeading': {
      color: 'secondary',
      fontWeight: '500',
      borderColor: 'secondary',
      display: 'none'
    },

    '.gonationLogo': {
      width: 'fit-content',
      '.poweredByText': {
        justifyContent: 'flex-start'
      }
    },
    '.copyright': {
      whiteSpace: 'nowrap',
      width: 'auto',
      display: 'flex',
      textAlign: 'right',
      justifyContent: 'flex-end',
      fontSize: '0.85rem'
    },
    '.multiButtonContainer': {
      display: 'none'
    }
  },

  ctaWidget: {
    display: 'none',
    a: {
      backgroundColor: 'secondary'
    }
  },

  loadingPage: {
    '.container': {
      backgroundColor: 'black'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    width: 'fit-content',
    // borderBottom: '2px solid',
    // borderColor: 'secondary',
    display: 'flex',
    flexDirection: 'column',
    fontSize: ['2.5rem', '3rem', '3.5rem', '5rem'],
    textTransform: 'capitalize',
    color: 'secondary',
    fontWeight: 'bold',
    letterSpacing: '2px',
    whiteSpace: 'pre-wrap',
    marginBottom: '1.5rem'
  },

  subtitle: {
    color: 'light',
    fontWeight: 'bold',
    textTransform: 'initial',
    order: '1',
    fontFamily: 'subheading',
    fontSize: ['1rem', '1.5rem', '2rem', '2.5rem'],
    textTransform: 'uppercase',
    borderBottom: '2px solid white'
  },

  text: {
    order: '3'
  },

  sideBySide1: {
    overflow: 'hidden',
    padding: ['2rem 1rem', '', '3rem', '5rem'],
    // background:
    //   'url(https://www.transparenttextures.com/patterns/brick-wall-dark.png)',
    '.content': {
      order: ['', '', '2'],
      width: ['', '', '55%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '5rem'],
      alignItems: 'center',
      textAlign: 'center',
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      }
    },
    '.lazyload-wrapper': {
      order: '1',
      width: ['', '', '45%'],
      maxHeight: ['', '', '60vh'],
      border: 'solid 10px white',
      boxShadow: '18px 20px #f300c7',
      transform: 'rotate(2deg)',
      '::before': {
        background: 'unset'
      },
      img: {
        // height: '100vh',
      }
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  sideBySide2: {
    overflow: 'hidden',
    padding: ['2rem 1rem', '', '3rem', '5rem'],
    flexDirection: ['', '', 'row'],
    // background:
    //   'url(https://www.transparenttextures.com/patterns/brick-wall-dark.png)',
    '.imageContainer': {
      order: ['', '', '2'],
      width: ['', '', '60%'],
      height: ['', '', '65vh'],
      position: 'relative',
      '::before': {
        content: ['""', '""', "''"],
        border: 'solid 3px',
        borderColor: 'white',
        backgroundColor: '#373737',
        position: 'absolute',
        height: '85%',
        width: '50vw',
        left: '10%',
        top: '15%',
        transform: 'rotate(-3deg)',
        border: 'solid 10px white',
        background:
          'url(https://res.cloudinary.com/gonation/image/upload/v1635258599/sites/viagastro/AdobeStock_134115811-_1_-_1.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center center'
        // boxShadow: '18px 20px #f300c7',
      },
      img: {
        // height: '100vh',
        border: 'solid 10px white',
        boxShadow: '18px 20px #f300c7',
        transform: 'rotate(2deg)'
      },
      '.image1': {
        position: ['', '', 'absolute'],
        top: '0%',
        left: '25%',
        width: ['', '', '75%'],
        height: ['', '', '75%']
      },
      '.image3': {
        position: ['', '', 'absolute'],
        bottom: '10%',
        width: ['', '', '60%'],
        height: ['', '', '50%']
      }
    },

    '.content': {
      order: ['', '', '1'],
      width: ['', '', '40%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 3.5rem'],
      alignItems: 'flex-start',
      textAlign: 'left'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  sideBySide3: {
    variant: 'customVariants.sideBySide2',
    '.content': {
      order: ['', '', '3'],
      width: ['', '', '40%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '4.5rem 1rem 4.5rem 6rem'],
      alignItems: 'flex-start',
      textAlign: 'left'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    backgroundSize: ['200%', 'cover'],
    '.section': {
      border: 'none',
      width: ['100%', '', '50%'],
      maxWidth: 'unset',
      margin: '0rem auto',
      height: 'auto',
      justifyContent: 'flex-end',
      alignItems: 'center',
      textAlign: 'center',
      padding: '2rem 1rem'
    },
    '.title': {
      variant: 'customVariants.title',
      border: 'none',
      padding: '0rem',
      fontSize: ['2rem', '2.5rem', '3.5rem', '5.5rem'],
      textShadow: '2px 2px 10px black',
      color: 'white'
      // fontFamily: 'display2',
      // textTransform: 'capitalize',
      // fontWeight: 'normal',
    },
    '.subtitle': {},
    '.text': {
      color: 'white'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    '.imageContainer': {
      order: ['2', '', '2']
    },
    maxHeight: ['', '', '50vh'],
    '.content': {
      order: ['1', '1'],
      padding: ['1rem', '', '', '4rem', '5rem'],
      alignItems: 'flex-start',
      textAlign: 'left'
    },
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'left',
      margin: '1rem 0rem',
      // display: 'none',
      fontWeight: 'normal',
      border: 'none',
      // borderBottom: '2px solid',
      // borderColor: 'secondary',
      width: 'fit-content',
      fontSize: '0rem',
      color: 'background',
      '::before': {
        variant: 'customVariants.title',
        content: "'Recent News'" // ! bad coding but could't be bothered to shadow
      }
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'left',
      margin: '1rem 0rem'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'left',
      margin: '1rem 0rem 2rem',
      maxWidth: 'unset'
    },
    '.date': {
      textAlign: 'left',
      margin: '1rem 0rem',
      color: 'white',
      order: '2'
    },
    '.shoutCTABtns': {
      order: '5',
      '.shoutCTA': {
        variant: 'buttons.primary',
        padding: '1rem',
        fontSize: '1rem'
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageSocial: {
    variant: 'customVariants.homepageLocation',
    height: '90vh',
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['1.9rem', '2.5rem', '3rem']
    }
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide2'
  },

  homepageDrinks: {
    variant: 'customVariants.sideBySide3'
  },

  homepageSocial2: {
    variant: 'customVariants.homepageLocation',
    height: '90vh',
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['1.9rem', '2.5rem', '3rem']
    }
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide2'
  },

  homepageLocation: {
    // variant: 'customVariants.sideBySide1',
    padding: ['3rem 1rem', '', ''],
    height: '90vh',
    '.section': {
      maxWidth: 'unset'
    },
    '.title': {
      variant: 'customVariants.title',
      fontFamily: 'display'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
      fontSize: ['1.4rem', '', '2.5rem']
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    padding: ['3rem 1rem', '', '6rem 1rem', '7rem 1rem'],
    '.title': {
      fontSize: '2.5rem',
      color: 'secondary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    }
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menu: {
    border: 'solid 1px black',
    padding: ['1rem', '1rem'],
    margin: ['1rem 0.5rem', '', '', '', '1rem auto 3rem'],
    width: ['calc(100% - 1rem)'],
    backgroundColor: 'transparent',

    '.menuContainer': {
      paddingBottom: '1rem'
    },
    '.tabsRow': {
      padding: '0rem 0rem 1rem',
      marginBottom: '2rem'
    },
    '#menuTab': {
      flexGrow: '1',
      width: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      maxWidth: 'unset',
      minWidth: 'unset',
      textAlign: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
      color: 'secondary',
      ':hover': {
        backgroundColor: 'primary',
        opacity: '0.7'
      }
    },
    '.menuItemInnerContainer, .menuItemContentContainer': {
      margin: '0rem'
    },
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      textTransform: 'uppercase',
      color: 'secondary',
      margin: ['1rem', '', '1rem 1.5rem'],
      paddingBottom: '1rem',
      textAlign: 'left',
      fontSize: ['1.5rem', '', '2rem']
    },

    '.menuItemName': {
      variant: 'customVariants.title',
      fontFamily: 'heading',
      fontWeight: '500',
      fontSize: ['1.25rem', '1.5rem', '', ''],
      margin: '0rem'
    },

    '.menuSectionDescription': {
      fontSize: '1.1rem',
      textAlign: 'left',
      margin: ['1rem', '', '1rem 1.5rem 2rem']
    },
    '.menuItemDescription': {},
    '.menuPriceContainer': {
      fontFamily: 'heading'
      // display: 'none',
    },
    '.menuItemPrice': {},
    '.variantsContainer': {
      // display: 'none',
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsContainer': {
      // display: 'none',
    },
    '.albumTitle': {
      variant: 'buttons.secondary'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      img: {
        // filter: 'brightness(1) invert(1)',
      }
    },
    '.eventItemImage': {
      minHeight: 'unset',
      maxHeight: 'unset',
      height: '100%'
    },
    '.eventDays, .eventDate': {
      color: 'secondary'
    },
    '.eventDaysContainer': {
      'svg, span': {
        color: 'secondary'
      },
      alignItems: 'center'
    },
    '.eventItemTitle': {
      color: 'secondary',
      fontSize: '1.75rem'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '4rem 1rem',
    order: '4',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'white',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },
      '.submitBtn': {
        variant: 'buttons.secondary',
        // borderRadius: '0px',
        textAlign: 'center',
        justifyContent: 'center'
      }
    }
  },

  locationMap: {
    order: '2',
    backgroundColor: 'secondary',
    color: 'white',
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderBottom: '2px solid',
      borderColor: 'white'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  // ? =======================
  // ? ====  Events Menu  ====
  // ? =======================

  eventsLeft: {
    variant: 'customVariants.sideBySide1'
  },
  eventsRight: {
    variant: 'customVariants.sideBySide2'
  },

  // ? ======================
  // ? ====  Press Menu  ====
  // ? ======================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    '.boxesHeading': {
      display: 'none'
    },
    '.box': {
      backgroundColor: '#c5c2c2',
      margin: ['1rem', '', '2rem'],
      width: ['100%', 'calc(50% - 0.5rem * 2)', 'calc(33.3% - 2rem * 2)']
    },
    '.imageContainer': {
      backgroundColor: 'black',
      img: {
        objectFit: 'contain'
      }
    },
    '.subtitle': {
      color: '#5d574e'
    }
  },

  pressPage: {},

  // ? =====================
  // ? ====  Team Menu  ====
  // ? =====================

  teamMenu: {},

  teamPage: {},

  // ? =====================
  // ? ====  blog Menu  ====
  // ? =====================

  blogMenu: {},

  blogPage: {},

  // ? ===========================
  // ? ====  Private Events   ====
  // ? ===========================

  privateEvents1: {
    variant: 'customVariants.sideBySide1',
    pb: ['20vh', '20vh', '20vh', '20vh', '20vh']
  }
}
