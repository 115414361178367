export default {
  primary: {
    color: 'light',
    border: 'solid 1px',
    fontSize: '0.7rem',
    fontWeight: '700',
    textTransform: 'uppercase',
    paddingBottom: '0rem',
    padding: '1rem 3rem',
    borderColor: 'light',
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: 'dark',
      color: 'light'
    }
  },
  secondary: {
    backgroundColor: 'transparent',
    padding: '1rem 2rem',
    color: 'light',
    border: 'none',
    border: 'solid 3px',
    borderColor: 'light',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    borderRadius: '0px',
    display: 'flex',
    fontSize: '1rem',
    transition: 'all ease-in-out 0.8s',
    ':hover': {
      // opacity: '0.4',
      backgroundColor: 'secondary',
      color: 'light'
    }
  }
}
